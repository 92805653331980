import { useState, useEffect } from 'react';

const useWindowSize = () => {
  const windowGlobal = typeof window !== 'undefined';

  const [width, setWidth] = useState<number | null>(null);
  const [height, setHeight] = useState<number | null>(null);

  const handleResize = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };

  useEffect(() => {
    if (windowGlobal) {
      handleResize();
      window.addEventListener('resize', handleResize);
    }

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return { width, height };
};

export default useWindowSize;
