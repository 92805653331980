import React, { useRef, useState, useEffect } from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';
import UnderlineHeading, { Underline } from '../components/underline';
import useWindowSize from '../utils/useWindowSize';

type ISectionCard = {
  heading: string;
  text: string[];
  listItems: string[];
};

type ISection = {
  heading: string;
  cards: ISectionCard[];
};

function LearningCard({ section, last }: { section: ISectionCard; last: boolean }) {
  const [width, setWidth] = useState<number | null>(null);
  const ref = useRef<HTMLDivElement>(null);
  const windowSize = useWindowSize();

  const { heading, text, listItems } = section;

  useEffect(() => {
    const newWidth = ref.current ? ref.current.offsetWidth : 0;
    setWidth(newWidth);
  }, [windowSize]);

  return (
    <div className={`w-full lg:w-1/2 ${!last ? 'mb-8' : ''} md:mb-0 md:p-4`}>
      <div
        style={{ minHeight: 350 }}
        className="h-full max-w-prose mx-auto drawn-border px-4 py-8 md:py-12 md:px-12 flex flex-col items-stretch flow"
      >
        <div ref={ref} className="flex items-center justify-center">
          <h3 className="text-xl md:text-2xl font-bold text-center w-full">{heading}</h3>
        </div>
        {width && <Underline width={width} />}
        {text && text.map(t => <p key={t}>{t}</p>)}
        {listItems && (
          <ul className="list-disc ml-4 md:ml-6 flow">
            {listItems.map(l => (
              <li key={`${heading}-${l}`}>{l}</li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
}

function Learning({ data }: { data: { pagesJson: { learningSections: ISection[] } } }) {
  const { learningSections } = data.pagesJson;

  return (
    <Layout heading="Learn with One to One" subheading="Lessons suitable for 8 - adult">
      <SEO title="About" />
      <div className="py-8">
        {learningSections.map((s, i) => (
          <div className={`${i !== 0 ? 'pt-8' : ''}`} key={s.heading}>
            <UnderlineHeading align="mx-auto" marginBottom="mb-8">
              <h2 className="text-xl md:text-3xl font-bold text-center">{s.heading}</h2>
            </UnderlineHeading>
            <div className="flex flex-wrap justify-center items-stretch">
              {s.cards.map((c, index) => (
                <LearningCard key={c.heading} last={index === s.cards.length - 1} section={c} />
              ))}
            </div>
          </div>
        ))}
      </div>
    </Layout>
  );
}

export default Learning;

export const query = graphql`
  {
    pagesJson(page: { eq: "learning" }) {
      learningSections {
        heading
        cards {
          heading
          text
          listItems
        }
      }
    }
  }
`;
